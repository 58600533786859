.container-fluid{
  padding:0 !important;
  overflow-x: hidden;
}

.helper{
  border:solid;
  border-color:red;
}

.logo {
  max-width: 150px;
  min-width: 100px;
  width: 100%;
  margin: 40px
}